// Design: https://www.figma.com/design/yPi4YLfFUMYDa0o0902qKj/ITC_QT-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E6%A4%9C%E7%B4%A2?node-id=1675-6685&t=3V325GrRr0U1qoGe-4
import "remixicon/fonts/remixicon.css"
import React, {
  Dispatch,
  ReactNode,
  ReactElement,
  useState,
  useCallback,
  useEffect,
} from "react"
import { Button } from "./button"
import * as styles from "./dialog.module.scss"

interface Props {
  open: boolean
  title: string
  confirmText?: string
  cancelText?: string
  onConfirm?: () => unknown | Promise<unknown>
  onCancel?: () => unknown | Promise<unknown>
  onClose?: Dispatch<void>
  danger?: boolean
  children: ReactNode
}

export const Dialog = React.memo((props: Props): ReactElement => {
  const {
    open,
    title,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    onClose,
    danger,
    children,
  } = props

  const [isOpened, setIsOpened] = useState(open)
  const handleClose = useCallback(() => {
    setIsOpened(false)
    onClose?.()
  }, [onClose])
  const handleConfirm = useCallback(async () => {
    await onConfirm?.()
    handleClose()
  }, [onConfirm, handleClose])
  const handleCancel = useCallback(async () => {
    await onCancel?.()
    handleClose()
  }, [onCancel, handleClose])

  useEffect(() => {
    setIsOpened(open)
  }, [open])

  return (
    <>
      {isOpened && (
        <div className={styles.overlay}>
          <div className={styles.content}>
            <button
              type="button"
              className={styles.closeButton}
              onClick={handleCancel}
            >
              <i className="ri-close-large-line"></i>
            </button>
            <h2>{title}</h2>
            <div className={styles.description}>{children}</div>

            <div className={styles.buttons}>
              {cancelText && (
                <Button variant="secondary" onClick={handleCancel}>
                  {cancelText}
                </Button>
              )}
              {confirmText && (
                <Button
                  variant={
                    danger ? "danger" : cancelText ? "primary" : "secondary"
                  }
                  onClick={handleConfirm}
                >
                  {confirmText}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
})
